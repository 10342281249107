import React, { useState } from 'react';
import { db } from '../lib/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function UploadPage() {
  const navigate = useNavigate();
  const [tsvData, setTsvData] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [arrayId, setArrayId] = useState('');

  const handleUpload = async () => {
    if (!tsvData.trim() || !datasetName.trim()) return;

    const rows = tsvData.split('\n').map(row => row.split('\t'));
    
    // Generate column names dynamically
    const headers = rows[0].map((_, index) => `col_${index}`);

    const formattedData = rows.map(row => {
      let rowObject = {};
      row.forEach((cell, index) => {
        rowObject[`col_${index}`] = cell || '';
      });
      return rowObject;
    });

    try {
      const docRef = doc(db, 'tsv_data', datasetName);
      await setDoc(docRef, { name: datasetName, headers, data: formattedData });

      setArrayId(datasetName);
      localStorage.setItem('lastUploadedDataset', datasetName); // Store last uploaded dataset
      alert("Data uploaded successfully!");
      navigate(`/edit?dataset=${encodeURIComponent(datasetName)}`); // Navigate automatically after save
    } catch (error) {
      console.error("Error uploading data: ", error);
      alert("Failed to upload data.");
    }
  };

  return (
    <div className="p-4 max-w-2xl mx-auto">
      <h1 className="text-4xl font-bebas mb-4">Upload Data</h1>
      <p className="mb-2 text-gray-700">Paste your TSV data below and save it to Firestore.</p>
      
      <input
        type="text"
        value={datasetName}
        onChange={(e) => setDatasetName(e.target.value)}
        placeholder="Enter dataset name"
        className="w-full p-2 mb-2 border rounded"
      />
      
      <textarea
        value={tsvData}
        onChange={(e) => setTsvData(e.target.value)}
        placeholder="Paste TSV data here..."
        className="w-full h-32 p-2 border rounded"
      />
      
      <div className="flex gap-2 mt-2">
        <button onClick={handleUpload} className="px-4 py-2 bg-blue-500 text-white rounded">Save & Next</button>
      </div>
      
      {arrayId && <p className="mt-2 text-sm">Saved as: <strong>{arrayId}</strong></p>}
    </div>
  );
}

export default UploadPage;