import React, { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function PublicLogPage() {
  const [publicPages, setPublicPages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPublicPages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'public'));
        const pages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPublicPages(pages);
      } catch (error) {
        console.error("Error fetching public pages:", error);
      }
    };
    fetchPublicPages();
  }, []);

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-4xl font-bebas mb-4">My Published Pages</h1>
      <table className="w-full border-collapse border rounded">
        <thead>
          <tr className="bg-gray-300">
            <th className="p-2 border">Page Name</th>
            <th className="p-2 border">Date Created</th>
            <th className="p-2 border">View</th>
          </tr>
        </thead>
        <tbody>
          {publicPages.map((page) => (
            <tr key={page.id} className="border-b">
              <td className="p-2 border">{page.title || 'Untitled'}</td>
              <td className="p-2 border">
                {page.createdAt ? new Date(page.createdAt.toDate()).toLocaleDateString() : 'Unknown'}
              </td>
              <td className="p-2 border text-center">
                <a href={`/public/${page.id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex gap-4">
        <button onClick={() => navigate('/')} className="px-4 py-2 bg-gray-500 text-white rounded">Go to Home</button>
        <button onClick={() => navigate('/display')} className="px-4 py-2 bg-green-500 text-white rounded">Create New Page</button>
      </div>
    </div>
  );
}

export default PublicLogPage;