import React from "react";

function LandingPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-gray-900">
      <h1 className="text-5xl font-bebas mb-4">Welcome!</h1>

      {/* Instagram Link */}
      <a 
        href="https://www.instagram.com/brasri_vin" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-500 text-lg underline hover:text-blue-700"
      >
        @brasri_vin
      </a>
    </div>
  );
}

export default LandingPage;