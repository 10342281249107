import React, { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function EditPage() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(localStorage.getItem('lastUploadedDataset') || 'prototype');
  const [editedData, setEditedData] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchDatasets = async () => {
      const querySnapshot = await getDocs(collection(db, 'tsv_data'));
      const datasetNames = querySnapshot.docs.map(doc => doc.id);
      setDatasets(datasetNames);
    };

    fetchDatasets();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedDataset) return;
      const docRef = doc(db, 'tsv_data', selectedDataset);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const fullData = docSnap.data().data || [];
        const storedHeaders = Object.keys(fullData[0] || {});
        setHeaders(storedHeaders);
        setTableData(fullData);
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [selectedDataset]);

  const handleEdit = (rowIndex, column, value) => {
    setEditedData(prev => ({
      ...prev,
      [rowIndex]: {
        ...prev[rowIndex],
        [column]: value,
      },
    }));
  };

  const handleSave = async () => {
    if (!selectedDataset) return;
    const updatedData = tableData.map((row, rowIndex) => ({
      ...row,
      ...editedData[rowIndex],
    }));

    const docRef = doc(db, 'tsv_data', selectedDataset);
    await updateDoc(docRef, { data: updatedData });

    setTableData(updatedData);
    setEditedData({});
    setIsEditing(false);
    alert('Changes saved successfully!');
  };

  const handleDiscard = () => {
    setEditedData({});
    setIsEditing(false);
  };

  const handleShow = () => {
    navigate(`/display?dataset=${encodeURIComponent(selectedDataset)}`);
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-4xl font-bebas mb-4">Edit Data</h1>
      <p className="mb-2 text-gray-700">Modify your dataset below:</p>

      <div className="mb-4 flex items-center justify-between">
        <div>
          <label className="block text-gray-700 mb-1">Select Dataset:</label>
          <select
            value={selectedDataset}
            onChange={(e) => {
              setSelectedDataset(e.target.value);
              localStorage.setItem('lastUploadedDataset', e.target.value);
            }}
            className="p-2 border rounded"
          >
            {datasets.map((dataset, index) => (
              <option key={index} value={dataset}>{dataset}</option>
            ))}
          </select>
        </div>
        <div className="flex gap-2">
          <button onClick={handleShow} className="px-4 py-2 bg-purple-500 text-white rounded">Show</button>
          {!isEditing && (
            <button onClick={() => setIsEditing(true)} className="px-4 py-2 bg-blue-500 text-white rounded">Edit</button>
          )}
          {isEditing && (
            <>
              <button onClick={handleSave} className="px-4 py-2 bg-green-500 text-white rounded">Save</button>
              <button onClick={handleDiscard} className="px-4 py-2 bg-gray-500 text-white rounded">Discard</button>
            </>
          )}
        </div>
      </div>

      {isDataLoaded && (
        <div className="overflow-auto border rounded bg-gray-100 p-2 relative">
          <table className="w-full border-collapse">
            <colgroup>
              <col className="w-8" />
              {headers.map((_, index) => (
                <col key={index} className="w-auto" />
              ))}
            </colgroup>
            <thead>
              <tr className="bg-gray-300">
                <th className="p-2 border text-left font-semibold">#</th>
                {headers.map((header, index) => (
                  <th key={index} className="p-2 border text-left font-semibold break-words">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex} className="border-b">
                  <td className="p-2 border text-center font-semibold">{rowIndex + 1}</td>
                  {headers.map((header, colIndex) => (
                    <td key={colIndex} className="p-2 border break-words">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedData[rowIndex]?.[header] ?? row[header]}
                          onChange={(e) => handleEdit(rowIndex, header, e.target.value)}
                          className="w-full p-1 border rounded break-words"
                        />
                      ) : (
                        row[header]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EditPage;