import React, { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

function PublicDisplayPage() {
  const { publicId } = useParams(); // Get the document ID from the URL
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSwitched, setIsSwitched] = useState(false); // Toggle state for row order
  const [hasAnyItalicText, setHasAnyItalicText] = useState(false); // ✅ NEW: Track if any italic text exists

  useEffect(() => {
    console.log("publicId from URL:", publicId);
    if (!publicId) return;
  
    const fetchPageData = async () => {
      console.log("Fetching Firestore document for:", publicId);
      const docRef = doc(db, 'public', publicId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        console.log("Document found:", docSnap.data());
        setPageData(docSnap.data());

        // ✅ Check if any row has italic text
        const hasItalic = docSnap.data().structuredData?.some(entry =>
          entry.type === 'row' && entry.content.some(c => c.align === 'italic' && c.text)
        );
        setHasAnyItalicText(hasItalic);
      } else {
        console.log("No document found.");
        alert("Page not found.");
      }
      setLoading(false);
    };
  
    fetchPageData();
  }, [publicId]);

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard!");
  };

  const handleSwitchDisplay = () => {
    setIsSwitched(prev => !prev);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="p-6 max-w-4xl mx-auto text-gray-900">
      {/* Title Row with Copy Link and Conditional Switch Button */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-4xl font-bebas">{pageData?.title || "Untitled Page"}</h1>
        <div className="flex gap-2">
          <button 
            onClick={handleCopyLink} 
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Copy Link
          </button>
          {/* ✅ HIDE SWITCH BUTTON IF THERE IS NO ITALIC DATA */}
          {hasAnyItalicText && (
            <button 
              onClick={handleSwitchDisplay} 
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Switch
            </button>
          )}
        </div>
      </div>

      {/* Description */}
      {pageData?.description && (
        <p className="text-lg text-gray-600 mb-4">{pageData.description}</p>
      )}

      {/* Content Display */}
      <div className="border rounded bg-gray-100 p-4">
        {pageData?.structuredData?.map((entry, index, arr) => {
          if (entry.type === 'row') {
            const leftText = entry.content.find(c => c.align === 'left')?.text || '';
            const rightText = entry.content.find(c => c.align === 'right')?.text || '';
            const italicText = entry.content.find(c => c.align === 'italic')?.text || '';

            const hasItalicText = Boolean(italicText);

            return (
              <div key={index} className="ml-6 pb-2">
                {/* SWITCHED DISPLAY: ROW2 First, then ROW1 */}
                {isSwitched ? (
                  <>
                    {/* ROW2: Italic Text (Only show if it has content) */}
                    {hasItalicText && (
                      <div className="italic text-gray-700 w-full pl-4 mt-1 font-lato">
                        {italicText}
                      </div>
                    )}

                    {/* ROW1: Left & Right Text */}
                    <div className="flex justify-between py-1">
                      <span>{leftText}</span>
                      <span className="ml-20">{rightText}</span>
                    </div>
                  </>
                ) : (
                  <>
                    {/* ROW1: Left & Right Text */}
                    <div className="flex justify-between py-1">
                      <span>{leftText}</span>
                      <span className="ml-20">{rightText}</span>
                    </div>

                    {/* ROW2: Italic Text (Only show if it has content) */}
                    {hasItalicText && (
                      <div className="italic text-gray-700 w-full pl-4 mt-1 font-lato">
                        {italicText}
                      </div>
                    )}
                  </>
                )}

                {/* ✅ ADDED SEPARATOR - Prevent separator after the last row */}
                {index !== arr.length - 1 && <hr className="border-t border-gray-300 mt-2" />}
              </div>
            );
          }

          // Display Headings or Other Text Normally
          return (
            <div key={index} className={
              entry.type === 'heading1' ? 'text-3xl font-bebas mt-6 font-normal' :
              entry.type === 'heading2' ? 'text-2xl font-oswald mt-4 ml-4 font-normal' :
              entry.type === 'heading3' ? 'text-xl font-oswald mt-2 ml-8 font-normal' :
              'ml-12 font-lato'
            }>
              {entry.text}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PublicDisplayPage;