import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UploadPage from './pages/UploadPage';
import EditPage from './pages/EditPage';
import DisplayPage from './pages/DisplayPage';
import DisplayOutputPage from './pages/DisplayOutputPage';
import PublicLogPage from './pages/PublicLogPage';
import PublicDisplayPage from './pages/PublicDisplayPage';
import LandingPage from "./pages/LandingPage";
import Navigation from './components/Navigation';

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/edit" element={<EditPage />} />
        <Route path="/display" element={<DisplayPage />} />
        <Route path="/display-output" element={<DisplayOutputPage />} />
        <Route path="/public-log" element={<PublicLogPage />} />
        <Route path="/public/:publicId" element={<PublicDisplayPage />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;