import React, { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { doc, getDoc, collection, addDoc } from 'firebase/firestore';

function DisplayOutputPage() {
  const [tableData, setTableData] = useState([]);
  const [columnPlacements, setColumnPlacements] = useState({});
  const [structuredData, setStructuredData] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [publicUrl, setPublicUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const dataset = localStorage.getItem("lastUploadedDataset") || "prototype";
      const docRef = doc(db, 'tsv_data', dataset);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const fullData = docSnap.data().data || [];
        setTableData(fullData);
      }
    };

    fetchData();

    // Load display settings from localStorage
    const savedSettings = JSON.parse(localStorage.getItem("displaySettings")) || {};
    setColumnPlacements(savedSettings);
  }, []);

  useEffect(() => {
    if (tableData.length > 0) {
      organizeData();
    }
  }, [tableData, columnPlacements]);

  const organizeData = () => {
    let structured = [];
    let groupedData = {};

    tableData.forEach(row => {
      let heading1 = '';
      let heading2 = '';
      let heading3 = '';
      let rowEntry = [];

      Object.keys(row).forEach((key) => {
        const placement = columnPlacements[key] || 'none';

        if (placement === 'heading1') {
          heading1 = row[key];
        } else if (placement === 'heading2') {
          heading2 = row[key];
        } else if (placement === 'heading3') {
          heading3 = row[key];
        } else if (placement === 'normal-left') {
          rowEntry.push({ text: row[key], align: 'left' });
        } else if (placement === 'normal-right') {
          rowEntry.push({ text: row[key], align: 'right' });
        } else if (placement === 'italic-below') {
          rowEntry.push({ text: row[key], align: 'italic' });
        }
      });

      const key = `${heading1}||${heading2}||${heading3}`;
      if (!groupedData[key]) {
        groupedData[key] = { heading1, heading2, heading3, rows: [] };
      }
      groupedData[key].rows.push(rowEntry);
    });

    let lastHeading1 = "";
    let lastHeading2 = "";
    let lastHeading3 = "";

    Object.values(groupedData).forEach(group => {
      if (group.heading1 !== lastHeading1) {
        structured.push({ type: 'heading1', text: group.heading1 });
        lastHeading1 = group.heading1;
        lastHeading2 = "";
        lastHeading3 = "";
      }
      if (group.heading2 !== lastHeading2) {
        structured.push({ type: 'heading2', text: group.heading2 });
        lastHeading2 = group.heading2;
        lastHeading3 = "";
      }
      if (group.heading3 !== lastHeading3) {
        structured.push({ type: 'heading3', text: group.heading3 });
        lastHeading3 = group.heading3;
      }

      group.rows.forEach((row, rowIndex) => {
        structured.push({ type: 'row', content: row, isLastRow: rowIndex === group.rows.length - 1 });
      });
    });

    setStructuredData(structured);
  };

  const handleSaveToPublic = async () => {
    try {
      const docRef = await addDoc(collection(db, 'public'), {
        title,
        description,
        structuredData,
        createdAt: new Date()
      });
      setPublicUrl(`${window.location.origin}/public/${docRef.id}`);
    } catch (error) {
      console.error("Error saving document: ", error);
      alert("Failed to save public page.");
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-4xl font-bebas mb-4">Formatted Display Output</h1>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter title"
        className="w-full p-2 mb-2 border rounded"
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Enter description"
        className="w-full h-16 p-2 border rounded"
      />
      <button onClick={handleSaveToPublic} className="px-4 py-2 bg-blue-500 text-white rounded mt-2">Save as Public Page</button>
      {publicUrl && <p className="mt-2">Public Link: <a href={publicUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{publicUrl}</a></p>}
      <div className="border rounded bg-gray-100 p-4 mt-4">
        {structuredData.map((entry, index) => (
          <div
            key={index}
            className={
              entry.type === 'heading1' ? 'text-3xl font-bebas mt-6 font-normal' :
              entry.type === 'heading2' ? 'text-2xl font-oswald mt-4 ml-4 font-light' :
              entry.type === 'heading3' ? 'text-xl font-oswald mt-2 ml-8 font-light' :
              'ml-12 font-lato'
            }
          >
            {entry.type === 'row' ? (
              <div className="ml-6 pb-2">
                <div className="flex justify-between py-1">
                  <span>{entry.content.find(c => c.align === 'left')?.text || ''}</span>
                  <span className="ml-20">{entry.content.find(c => c.align === 'right')?.text || ''}</span>
                </div>
                {entry.content.find(c => c.align === 'italic') && (
                  <div className="italic text-gray-700 w-full pl-4 mt-1 font-lato">
                    {entry.content.find(c => c.align === 'italic')?.text}
                  </div>
                )}
                {!entry.isLastRow && <hr className="border-t border-gray-300 mt-2" />}  
              </div>
            ) : (
              entry.text
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DisplayOutputPage;
