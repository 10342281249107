import React, { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';

function DisplayPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [columnPlacements, setColumnPlacements] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [sampleData, setSampleData] = useState([]);
  const [datasetName, setDatasetName] = useState('');

  useEffect(() => {
    // Extract dataset name from URL parameters
    const params = new URLSearchParams(location.search);
    const dataset = params.get('dataset') || localStorage.getItem('lastUploadedDataset') || 'prototype';
    setDatasetName(dataset);

    const fetchData = async () => {
      const docRef = doc(db, 'tsv_data', dataset);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const fullData = docSnap.data().data || [];
        setTableData(fullData);
        setSampleData(fullData.slice(0, 3)); // Use first 3 rows as sample preview
        setIsDataLoaded(true);
      }
    };

    fetchData();
  }, [location]);

  const handlePlacementChange = (column, value) => {
    setColumnPlacements(prev => ({ ...prev, [column]: value }));
  };

  const handleGenerateDisplay = () => {
    localStorage.setItem('displaySettings', JSON.stringify(columnPlacements));
    navigate('/display-output');
  };

  const renderPreviewRow = (row) => {
    return (
      <>
        <tr>
          {Object.keys(tableData[0] || {}).map((key, index) => {
            if (columnPlacements[key] === 'normal-left') {
              return <td key={index} className="p-2 text-left">{row[key]}</td>;
            }
            if (columnPlacements[key] === 'normal-right') {
              return <td key={index} className="p-2 pl-16">{row[key]}</td>; // Indented tab effect
            }
            return null;
          })}
        </tr>
        <tr>
          {Object.keys(tableData[0] || {}).map((key, index) => {
            if (columnPlacements[key] === 'italic-below') {
              return (
                <td key={index} colSpan={Object.keys(tableData[0] || {}).length} className="p-2 italic text-center">{row[key]}</td>
              );
            }
            return null;
          })}
        </tr>
      </>
    );
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-4xl font-bebas mb-4">Display Settings</h1>
      <p className="mb-2 text-gray-700">Selected dataset: <strong>{datasetName}</strong></p>

      <h2 className="text-2xl font-oswald mb-3">Live Preview</h2>
      <div className="overflow-auto border rounded bg-gray-100 p-2 mb-6">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {Object.keys(sampleData[0] || {}).map((key, index) => (
                columnPlacements[key] && columnPlacements[key].startsWith('heading') ? (
                  <th key={index} className="p-2 border text-left font-bold text-lg">{sampleData[0][key]}</th>
                ) : null
              ))}
            </tr>
          </thead>
          <tbody>
            {sampleData.map((row, rowIndex) => renderPreviewRow(row))}
          </tbody>
        </table>
      </div>

      <button
        onClick={handleGenerateDisplay}
        className="mt-6 px-4 py-2 bg-green-500 text-white rounded"
      >
        Generate Display
      </button>

      <h2 className="text-2xl font-oswald mb-3 mt-6">Full Table View</h2>
      <div className="overflow-auto border rounded bg-gray-100 p-2">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {Object.keys(tableData[0] || {}).map((column, index) => (
                <th key={index} className="p-2 border text-left font-semibold">
                  <select
                    value={columnPlacements[column] || 'none'}
                    onChange={(e) => handlePlacementChange(column, e.target.value)}
                    className="p-1 border rounded w-full"
                  >
                    <option value="none">None</option>
                    <option value="heading1">Heading 1</option>
                    <option value="heading2">Heading 2</option>
                    <option value="heading3">Heading 3</option>
                    <option value="normal-left">Normal-left</option>
                    <option value="normal-right">Normal-right</option>
                    <option value="italic-below">Italic-below</option>
                  </select>
                </th>
              ))}
            </tr>
            <tr className="bg-gray-300">
              {Object.keys(tableData[0] || {}).map((key, index) => (
                <th key={index} className="p-2 border text-left font-semibold">{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b">
                {Object.keys(tableData[0] || {}).map((key, colIndex) => (
                  <td key={colIndex} className="p-2 border">{row[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DisplayPage;