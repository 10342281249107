import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation();
  
  // ✅ Hide navbar on the landing page "/"
  if (location.pathname === "/") {
    return null;
  }

  // Hide navbar for public display pages
  const hideNavbar = location.pathname.startsWith("/public/");

  if (hideNavbar) {
    return null; // Don't render anything
  }

  return (
    <nav className="p-4 bg-gray-200 flex gap-4">
      <Link to="/upload">New</Link>
      <Link to="/edit">Edit</Link>
      <Link to="/display">Show</Link>
    </nav>
  );
}

export default Navigation;